import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/login',
    name: 'login',
    component: () => import('../vues/pages/loginPage.vue')
  },
  {
    path: '/lost-password',
    name: 'lost-password',
    component: () => import('../vues/pages/lostPasswordPage.vue')
  },
  {
    path: '/password-email-sent',
    name: 'password-email-sent',
    component: () => import('../vues/pages/passwordEmailSentPage.vue')
  },
  {
    path: '/new-password',
    name: 'new-password',
    component: () => import('../vues/pages/newPasswordPage.vue')
  },
  {
    path: '/register-from-invitation',
    name: 'register-from-invitation',
    component: () => import('../vues/pages/registerFromInvitationPage.vue')
  },
  {
    path: '/directory',
    name: 'directory',
    component: () => import('../vues/pages/directoryPage.vue')
  },
  {
    path: '/',
    name: 'home',
    component: () => import('../vues/pages/projectsPage.vue'),
  },
  {
    path: '/projects',
    name: 'projects',
    component: () => import('../vues/pages/projectsPage.vue'),
  },
  {
    path: '/projects/sites/:project_id',
    name: 'site',
    component: () => import('../vues/pages/projectPage.vue'),
  },
  {
    path: '/projects/sites/:project_id/category/:category_id',
    name: 'category',
    component: () => import('../vues/pages/categoryPage.vue'),
  },
  {
    path: '/projects/sites/:project_id/category/:category_id/source/:module_id',
    name: 'emission-source',
    component: () => import('../vues/pages/emissionSource.vue'),
  },
  {
    path: '/projects/sites/:project_id/category/:category_id/subcategory/:sub_category_id/add',
    name: 'add-emission-source-with-selected-subcategory',
    component: () => import('../vues/pages/addEmissionSource.vue'),
  },
  {
    path: '/projects/sites/:project_id/category/:category_id/add',
    name: 'add-emission-source',
    component: () => import('../vues/pages/addEmissionSource.vue'),
  },
  // {
  //   path: '/projects/sites/:project_id/scope/:scope_id/providers',
  //   name: 'providers',
  //   component: () => import('../vues/pages/providersPage.vue'),
  // },
  // {
  //   path: '/projects/sites/:project_id/scope/:scope_id/waste-treatment',
  //   name: 'waste-treatment',
  //   component: () => impor../vues/old/wasteTreatmentPage.vuevue'),
  // },
  // {
  //   path: '/projects/sites/:project_id/scope/:scope_id/waste-treatment/:module_id',
  //   name: 'emission-source-waste-treatment',
  //   component: () => import('../vues/pages/emissionSource.vue'),
  // },
  // {
  //   path: '/projects/:project_id/scope/:scope_id/waste-treatment/add',
  //   name: 'add-emission-source-waste-treatment',
  //   component: () => import('../vues/pages/addEmissionSource.vue'),
  // },
  // {
  //   path: '/projects/sites/:project_id/scope/:scope_id/customers',
  //   name: 'customers',
  //   component: () => impor../vues/old/customersPage.vuevue'),
  // },
  // {
  //   path: '/projects/sites/:project_id/scope/:scope_id/customers/:module_id',
  //   name: 'emission-source-customers',
  //   component: () => import('../vues/pages/emissionSource.vue'),
  // },
  // {
  //   path: '/projects/:project_id/scope/:scope_id/customers/add',
  //   name: 'add-emission-source-customers',
  //   component: () => import('../vues/pages/addEmissionSource.vue'),
  // },
  // {
  //   path: '/projects/sites/:project_id/scope/:scope_id/providers/:provider_id',
  //   name: 'provider',
  //   component: () => import('../vues/pages/providerPage.vue'),
  // },
  // {
  //   path: '/projects/sites/:project_id/scope/:scope_id/providers/:provider_id/:module_id',
  //   name: 'emission-source-provider',
  //   component: () => import('../vues/pages/emissionSource.vue'),
  // },
  {
    path: '/projects/sites/:project_id/perimeters-creation',
    name: 'perimeters-creation',
    component: () => import('../vues/pages/perimetersCreationPage.vue'),
  },
  {
    path: '/projects/sites/:project_id/perimeters-creation-from-invoice',
    name: 'perimeters-creation-from-invoice',
    component: () => import('../vues/pages/perimetersCreationFromInvoicePage.vue'),
  },
  // {
  //   path: '/projects/:project_id/scope/:scope_id/providers/:provider_id/add',
  //   name: 'add-emission-source-provider',
  //   component: () => import('../vues/pages/addEmissionSource.vue'),
  // },
  // {
  //   path: '/projects/sites/:project_id/scope/:scope_id/employees',
  //   name: 'employees',
  //   component: () => import('../vues/old/employeesPage.vue'),
  // },
  // {
  //   path: '/projects/sites/:project_id/scope/:scope_id/employees/:module_id',
  //   name: 'emission-source-employees',
  //   component: () => import('../vues/pages/emissionSource.vue'),
  // },
  {
    path: '/board',
    name: 'board',
    component: () => import('../vues/pages/carbonResultsPage.vue'),
  },
  {
    path: '/pilot',
    name: 'pilot',
    component: () => import('../vues/pages/pilotPage.vue'),
  },
  {
    path: '/pilot/projected',
    name: 'projected',
    component: () => import('../vues/pages/projectedPage.vue'),
  },
  {
    path: '/pilot/addplan',
    name: 'add-action-plan',
    component: () => import('../vues/pages/addCarbonActionPlan.vue'),
  },
  {
    path: '/pilot/plan/:action_plan_id',
    name: 'action-plan',
    component: () => import('../vues/pages/carbonActionPlan.vue'),
  },
  {
    path: '/progress',
    name: 'progress',
    component: () => import('../vues/pages/progressPage.vue'),
  },
  {
    path: '/progress/contact',
    name: 'contact',
    component: () => import('../vues/pages/contactPage.vue'),
  },
  {
    path: '/progress/contact/confirmation',
    name: 'contact-confirmation',
    component: () => import('../vues/pages/contactConfirmationPage.vue'),
  },
  {
    path: '/parameters',
    name: 'parameters',
    component: () => import('../vues/pages/parametersPage.vue'),
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'not-found',
    component: () => import('../vues/pages/notFoundPage.vue'),
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  },
})

export default router
